import React, { FC, memo, ReactNode } from 'react';
import { Modal } from 'shared/ui/Modal';
import { Button } from 'shared/ui/Button';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { WarningPing } from '../WarningPing';

interface ConfirmModalProps {
  isOpened: boolean;
  title?: string;
  description?: ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
  isConfirmDisabled?: boolean;
}

export const ConfirmModal: FC<ConfirmModalProps> = memo((props) => {
  const { isOpened, title, description, onOk, onCancel, isLoading, isConfirmDisabled } = props;

  const { t } = useAppTranslation('common');

  const actions = (): ReactNode => (
    <div className="flex space-x-3">
      {onCancel && (
        <Button containerClassName="w-full" theme="secondary" isLoading={isLoading} onClick={onCancel}>
          {t('Cancel')}
        </Button>
      )}
      {onOk && (
        <Button containerClassName="w-full" isLoading={isLoading} isDisabled={isConfirmDisabled} onClick={onOk}>
          {t('Confirm')}
        </Button>
      )}
    </div>
  );

  return (
    <Modal className="max-w-[400px]" isOpen={isOpened} onClose={onCancel} closeIcon={false} footer={actions()}>
      <div className="mb-2">
        <WarningPing type="warning" />
      </div>
      <div className="font-semibold text-xl mb-3">{title}</div>
      <div className="text-primaryLight font-normal">{description}</div>
    </Modal>
  );
});
