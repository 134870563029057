import { Divider } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { Box } from 'entities/Box';
import { useGetSizeCodesForBookingQuery } from 'entities/SizeCode';
import { BoxSizeFilter } from 'features/BoxSizeFilter';
import { requestFormModalActions, RequestModalMode } from 'widgets/RequestModal';
import { SizeCodeCollapse } from 'features/SizeCodeCollapse';
import { SizeGuideModal, sizeGuideModalActions } from 'features/SizeGuideModal';
import { Button } from 'shared/ui/Button';
import { useGetRentOptionsQuery } from 'entities/RentOption';
import { useViewMode } from '../utils/hooks/useViewMode';
import { WarehouseMap } from './WarehouseMap';
import { ReactComponent as DocumentIcon } from 'shared/assets/icons/DocumentIcon.svg';

interface SelectSizeContentProps {
  selectedBoxId: string | undefined;
  selectedWarehouseId: string | undefined;
  onSelectBox: (box: Box, sizeCodeId: string) => void;
  onCompleteStep: (box: Box, sizeCodeId: string) => void;
}

export const SelectSizeContent: FC<SelectSizeContentProps> = memo((props) => {
  const { selectedBoxId, selectedWarehouseId, onCompleteStep, onSelectBox } = props;

  const { t } = useAppTranslation('booking');
  const dispatch = useAppDispatch();

  const { data: sizeCodes } = useGetSizeCodesForBookingQuery({ warehouseId: selectedWarehouseId }, { skip: !selectedWarehouseId });
  const { data: rentOptions } = useGetRentOptionsQuery({ warehouseId: selectedWarehouseId }, { skip: !selectedWarehouseId });

  const { viewMode, setMapMode, setListMode } = useViewMode();

  const handleOpenRequestFormModal = useCallback((): void => {
    dispatch(
      requestFormModalActions.openRequestModal({
        title: t('Let`s get to know each other better!'),
        description: t('Please provide us with your information so that we can suggest the best pricing options that meet your needs.'),
        mode: RequestModalMode.REQUEST_OFFER,
      }),
    );
  }, [dispatch, t]);

  const openSizeGuideModal = useCallback((): void => {
    dispatch(sizeGuideModalActions.setOpenModal(true));
  }, [dispatch]);

  return (
    <div className="bg-secondaryLight rounded-lg">
      <Button
        containerClassName="mb-3"
        theme="clear"
        size="large"
        icon={<DocumentIcon className="[&>path]:stroke-accent" />}
        iconPosition="prev"
        onClick={viewMode === 'list' ? setMapMode : setListMode}
      >
        {t(viewMode === 'list' ? 'Show map' : 'Back to list')}
      </Button>
      <BoxSizeFilter sizeCodes={sizeCodes} />
      <div className="mt-4 flex items-end justify-between flex-wrap">
        <div>
          <div>{t('Did not find what you were looking for?')}</div>
          <Button theme="clear" className="underline" fontSize="large" onClick={handleOpenRequestFormModal}>
            {t('Request an offer')}
          </Button>
        </div>
        <Button theme="clear" fontSize="large" onClick={openSizeGuideModal}>
          {t('Size Guide')}
        </Button>
      </div>
      <Divider className="border-primary" />
      {viewMode === 'list' ? (
        <SizeCodeCollapse
          sizeCodes={sizeCodes}
          rentOptions={rentOptions}
          selectedBoxId={selectedBoxId}
          onSelectBox={onSelectBox}
          onBookBox={onCompleteStep}
        />
      ) : (
        <WarehouseMap selectedWarehouseId={selectedWarehouseId} selectedBoxId={selectedBoxId} onSelectBox={onSelectBox} />
      )}
      <SizeGuideModal sizeCodes={sizeCodes} />
    </div>
  );
});
