import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { InvoicesListFilterKeys, InvoiceType } from '../../model/types';
import { MultipleSelect } from 'shared/ui/MultipleSelect';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalSettings } from 'app/appState';

interface InvoiceTypeSelectProps {
  value: string[];
  onChange: (value: string[]) => void;
  onClear: (filterKey: string) => void;
}

export const InvoiceTypeSelect: FC<InvoiceTypeSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation(['contracts', 'common']);

  const globalSettings = useAppSelector(getGlobalSettings);

  const invoiceTypeOptions = useMemo<Array<{ label: string; value: InvoiceType }>>(() => {
    return [
      { label: t('Rental invoices'), value: InvoiceType.RENTAL },
      { label: t('Deposit invoices'), value: InvoiceType.DEPOSIT },
      { label: t('Personal invoices'), value: InvoiceType.PERSONAL },
      { label: t('Late charge invoices'), value: InvoiceType.LATE_CHARGE },
    ];
  }, [t]);

  const invoiceWithDepositTypeOptions = useMemo<Array<{ label: string; value: InvoiceType }>>(() => {
    return [
      { label: t('Rental invoices'), value: InvoiceType.RENTAL_WITH_DEPOSIT },
      { label: t('Personal invoices'), value: InvoiceType.PERSONAL },
      { label: t('Late charge invoices'), value: InvoiceType.LATE_CHARGE },
    ];
  }, [t]);

  const clearFilter = useCallback(() => {
    onClear(InvoicesListFilterKeys.invoiceTypes);
  }, [onClear]);

  const handleChange = useCallback(
    (value: string[]): void => {
      value.length ? onChange(value) : clearFilter();
    },
    [onChange, clearFilter],
  );

  return (
    <MultipleSelect
      containerClassName="flex-1"
      className="w-full"
      label={t('Invoice type')}
      placeholder={t('All')}
      bordered
      allowClear
      options={globalSettings?.isStandaloneDeposit ? invoiceTypeOptions : invoiceWithDepositTypeOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
