import React, { FC, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { WarehouseForBooking } from 'entities/Warehouse';
import { Trans } from 'react-i18next';
import { Image } from 'antd';
import { ReactComponent as ClockIcon } from 'shared/assets/icons/ClockIcon.svg';
import { ReactComponent as CameraIcon } from 'shared/assets/icons/CameraIcon.svg';
import { ReactComponent as TrolleyIcon } from 'shared/assets/icons/TrolleyIcon.svg';
import { ReactComponent as PaymentCardIcon } from 'shared/assets/icons/PaymentCardIcon.svg';
import WarehouseDefaultImage from 'shared/assets/images/WarehouseDefaultImage.png';
import { Tag } from 'shared/ui/Tag';
import { useGetCurrencySymbol } from 'app/appState';
import { ReactComponent as EmailIcon } from 'shared/assets/icons/EmailIcon.svg';
import { ReactComponent as PhoneIcon } from 'shared/assets/icons/PhoneIcon.svg';
import { ReactComponent as SmartphoneIcon } from 'shared/assets/icons/SmartphoneIcon.svg';
import { CopyTooltip } from 'shared/ui/CopyTooltip';
import { Paragraph } from 'shared/ui/Paragraph';
import { useGeolocation } from 'shared/utils/hooks/useGeolocation';
import { SizeGroupsTags } from 'entities/SizeCode';
import { Map } from 'features/Map';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { getBoxDiscountedPrice } from 'entities/Box';
import { Button } from 'shared/ui/Button';

interface WarehouseInfoStackProps {
  warehouseInfo: Nullable<WarehouseForBooking>;
}

export const WarehouseInfoStack: FC<WarehouseInfoStackProps> = (props) => {
  const { warehouseInfo } = props;

  const { t } = useAppTranslation('booking');
  const currencySymbol = useGetCurrencySymbol();
  const geolocationCoords = useGeolocation();

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const warehouseImage = warehouseInfo?.imageUrl || WarehouseDefaultImage;
  const fullAddress = `${warehouseInfo?.address || ''} ${warehouseInfo?.zip || ''}`;
  const discountedPrice = getBoxDiscountedPrice({
    warehouseDiscounts: warehouseInfo?.warehouseDiscounts,
    boxPrice: warehouseInfo?.minPrice,
  });

  const mapCenter = warehouseInfo ? { lat: warehouseInfo.latitude, lng: warehouseInfo.longitude } : geolocationCoords;
  const mapMarkers = warehouseInfo
    ? [{ id: warehouseInfo.warehouseId, position: { lat: warehouseInfo.latitude, lng: warehouseInfo.longitude } }]
    : undefined;

  return (
    <div>
      <div className="font-semibold text-2xl">{warehouseInfo?.name}</div>
      <CopyTooltip className="cursor-pointer text-primaryLight text-sm font-normal">{fullAddress}</CopyTooltip>
      {/* <img className="rounded-lg h-[139px] w-full object-cover my-4" src={warehouseImage} alt="Warehouse photo" /> */}

      <div className="my-1">
        <Image
          height={139}
          width="100%"
          className="rounded-lg object-cover"
          src={warehouseImage}
          preview={{
            visible: isPreviewVisible,
            scaleStep: 3,
            src: warehouseImage,
            onVisibleChange: (value) => {
              setIsPreviewVisible(value);
            },
          }}
        />
      </div>

      <Button
        containerClassName="items-end"
        theme="clear"
        onClick={() => {
          setIsPreviewVisible(true);
        }}
      >
        {t('Expand image')}
      </Button>

      <div className="text-2xl font-semibold mb-4">
        <Trans
          t={t}
          i18nKey={`${
            discountedPrice ? 'From <0>{{discountedPrice}} {{currencySymbol}}/month</0>' : ''
          } <1>From {{minPrice}} {{currencySymbol}}/month</1>`}
          components={[
            <span key="0" className="text-accent text-xl font-semibold" />,
            <div key="1" className={discountedPrice ? 'line-through text-error' : 'text-primary text-xl font-semibold'} />,
          ]}
          values={{ minPrice: warehouseInfo?.minPrice, currencySymbol, discountedPrice }}
        />
      </div>
      <div className="space-y-2.5 mb-4">
        <div className="flex items-center space-x-2.5">
          <EmailIcon className="shrink-0 [&>path]:stroke-accent" />
          <CopyTooltip className="cursor-pointer text font-normal">{warehouseInfo?.email}</CopyTooltip>
        </div>
        <div className="flex items-center space-x-2.5">
          <PhoneIcon className="shrink-0 [&>path]:stroke-accent" />
          <CopyTooltip className="cursor-pointer text font-normal">{warehouseInfo?.phone}</CopyTooltip>
        </div>
      </div>
      <div className="text-success mb-7">
        {t('{{availableBoxesAmount}} boxes free', { availableBoxesAmount: warehouseInfo?.availableBoxesAmount })}
      </div>
      <SizeGroupsTags sizeCodes={warehouseInfo?.sizeCodes} />
      <div className="flex flex-wrap gap-2 my-7">
        <Tag icon={<CameraIcon className="[&>path]:fill-accent" />}>{t('Security cameras monitoring')}</Tag>
        <Tag icon={<ClockIcon className="[&>*]:stroke-accent [&>*]:stroke-1" />}>{t('24 / 7 direct access')}</Tag>
        <Tag icon={<TrolleyIcon className="[&>path]:fill-accent" />}>{t('Free use of trolleys')}</Tag>
        <Tag icon={<SmartphoneIcon className="[&>path]:stroke-accent" />}>{t('Opened by app')}</Tag>
        <Tag icon={<PaymentCardIcon className="stroke-accent" />}>{t('Online payments')}</Tag>
      </div>
      <Paragraph className="text-base font-normal !mb-7 [&>*]:text-sm [&>*]:font-normal" rows={11}>
        {getLocalizedString(warehouseInfo?.description)}
      </Paragraph>
      <Map
        mapContainerClassName="h-[280px]"
        mapId="warehouseMap"
        center={mapCenter}
        withMarkerPopups={false}
        markers={mapMarkers}
        zoom={14}
      />
    </div>
  );
};
