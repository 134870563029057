import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { ReactComponent as SelectArrow } from 'shared/assets/icons/SelectArrow.svg';
import i18n from 'app/config/i18Config/i18n';
import { AppLanguage } from 'app/config/i18Config/types';
import { APP_LANGUAGE_KEY, languageToShortLabelMap } from 'shared/utils/constants';
import { getLoggedUserData, useChangeUserDetailsMutation } from 'entities/User';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalSettings } from 'app/appState/model/selectors/getGlobalSettings';

export const LangSwitcher: FC = memo(() => {
  const [language, setLanguage] = useState<AppLanguage>(AppLanguage.en);

  const loggedUser = useAppSelector(getLoggedUserData);
  const globalSettings = useAppSelector(getGlobalSettings);

  const [changeUserLanguage] = useChangeUserDetailsMutation();

  const handleLanguageChange = useCallback(
    async (language: AppLanguage): Promise<void> => {
      await i18n.changeLanguage(language);

      if (loggedUser && loggedUser.language !== language) {
        await changeUserLanguage({ userId: loggedUser.userId, params: { language } });
      }

      localStorage.setItem(APP_LANGUAGE_KEY, language);

      setLanguage(language);
    },
    [changeUserLanguage, loggedUser],
  );

  const initializeLanguage = useCallback(async (): Promise<void> => {
    if (loggedUser?.language) {
      await handleLanguageChange(loggedUser.language as AppLanguage);
    } else {
      const storedLanguage = localStorage.getItem(APP_LANGUAGE_KEY) as AppLanguage;

      if (Object.values(AppLanguage).includes(storedLanguage)) {
        setLanguage(storedLanguage);
      }
    }
  }, [handleLanguageChange, loggedUser?.language]);

  useEffect(() => {
    void initializeLanguage();
  }, [initializeLanguage]);

  const bindDropdownToContainer = (): HTMLElement => document.getElementById('container') as HTMLElement;

  const appLanguages = globalSettings?.availableLanguages
    .filter((lang: string): lang is AppLanguage => Object.values(AppLanguage).includes(lang as AppLanguage))
    .map((language: AppLanguage) => ({
      label: languageToShortLabelMap[language],
      value: language,
    }));

  return (
    <div id="container">
      <Select
        options={appLanguages}
        value={language}
        size="large"
        suffixIcon={<SelectArrow />}
        getPopupContainer={bindDropdownToContainer}
        onChange={handleLanguageChange}
      />
    </div>
  );
});
