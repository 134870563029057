import { Divider, Form } from 'antd';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { UserAccountType, UserRoles, CreateUserParams } from 'entities/User';
import { useCreateUser } from 'features/CreateUser';
import { stepperModalActions } from 'features/StepperModal';
import { Button } from 'shared/ui/Button';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { DatePicker } from 'shared/ui/DatePicker';
import { Input } from 'shared/ui/Input';
import { PhoneInput } from 'shared/ui/PhoneInput';
import { Select } from 'shared/ui/Select';
import { CLIENT_DATE_FORMAT, languageToLabelMap } from 'shared/utils/constants';
import { PHONE_NUMBER_REGEX } from 'shared/utils/regex';
import { AppLanguage } from 'app/config/i18Config/types';
import { datePickerValueUnder18, disableFutureDatesUnder18 } from 'shared/utils/helpers/disable18YearsDatePicker';
import { getGlobalSettings } from 'app/appState/model/selectors/getGlobalSettings';
import { getUserData } from '../model/selectors/getUserData';
import { createUserModalActions } from '../model/slice/createUserModalSlice';
import { RoleSelect } from './RoleSelect';
import { getSelectedRole } from '../model/selectors/getSelectedRole';

interface AccountDetailsStepProps {
  onSuccess: () => void;
}

export const AccountDetailsStep: FC<AccountDetailsStepProps> = memo((props) => {
  const { onSuccess } = props;

  const dispatch = useAppDispatch();

  const { t } = useAppTranslation('user');

  const [form] = Form.useForm();

  const { createUser, isLoading, isOpenedConfirm, closeConfirmModal, openConfirmModal } = useCreateUser(onSuccess);

  const userData = useAppSelector(getUserData);
  const selectedRole = useAppSelector(getSelectedRole);
  const globalSettings = useAppSelector(getGlobalSettings);

  useEffect(() => {
    userData ? form.setFieldsValue(userData) : form.resetFields();
  }, [form, userData]);

  const goToChooseWarehouseStep = useCallback(
    (formValues: CreateUserParams): void => {
      dispatch(stepperModalActions.changeActiveStepIndex(1));
      dispatch(createUserModalActions.setUserData(formValues));
    },
    [dispatch],
  );

  const isAdditionalStepsNeeded = selectedRole === UserRoles.MANAGER || selectedRole === UserRoles.STAFF;

  const isAddressDataNeeded = selectedRole === UserRoles.CUSTOMER;

  const submit = useCallback(
    (formValues: CreateUserParams): void => {
      isAdditionalStepsNeeded ? goToChooseWarehouseStep(formValues) : openConfirmModal();
    },
    [goToChooseWarehouseStep, isAdditionalStepsNeeded, openConfirmModal],
  );

  const handleCreateUser = useCallback((): void => {
    const userData: CreateUserParams = form.getFieldsValue();

    createUser(userData);
  }, [createUser, form]);

  const languages = globalSettings?.availableLanguages
    .filter((lang: string): lang is AppLanguage => Object.values(AppLanguage).includes(lang as AppLanguage))
    .map((language: AppLanguage) => ({
      label: languageToLabelMap[language],
      value: language,
    }));

  return (
    <Form className="flex flex-col" name="accountDetailsStep" layout="vertical" form={form} onFinish={submit}>
      <div className="font-semibold text-2xl mb-4">{t('Account details')}</div>
      <div className="flex gap-6">
        <div className="flex-1">
          <Form.Item label={t('Name')} name="firstName" rules={[{ required: true, message: t('Please, enter name') }]}>
            <Input placeholder={t('Enter name')} bordered />
          </Form.Item>
          <Form.Item label={t('Last name')} name="lastName" rules={[{ required: true, message: t('Please, enter last name') }]}>
            <Input placeholder={t('Enter last name')} bordered />
          </Form.Item>
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[
              { required: true, message: t('Please, enter email') },
              { type: 'email', message: t('Please enter correct email') },
            ]}
          >
            <Input placeholder={t('Enter email')} bordered />
          </Form.Item>
          <Form.Item
            label={t('Phone')}
            name="phone"
            rules={[
              { pattern: PHONE_NUMBER_REGEX, message: t('Please provide a valid phone number') },
              { required: true, message: `${t('Please, enter phone number')}!` },
            ]}
          >
            <PhoneInput placeholder={t('Enter phone number')} bordered initialValue={userData?.phone} />
          </Form.Item>
          <Form.Item label={t('Language')} name="language" rules={[{ required: true, message: t('Please, select language') }]}>
            <Select placeholder={t('Select language')} bordered options={languages || []} />
          </Form.Item>
        </div>
        <div className="flex-1">
          <Form.Item label={t('Identification number')} name="identificationNumber">
            <Input placeholder={t('Enter identification number')} bordered />
          </Form.Item>
          <Form.Item className="w-full" name="dateOfBirth" label={t('Date of birth')}>
            <DatePicker
              className="h-[52px] w-full"
              format={CLIENT_DATE_FORMAT}
              placeholder={t('Date of birth')}
              inputReadOnly
              disabledDate={disableFutureDatesUnder18}
              defaultPickerValue={datePickerValueUnder18}
            />
          </Form.Item>
          <RoleSelect />
          <Form.Item label={t('Account type')} name="accountType" rules={[{ required: true, message: t('Please, select account type') }]}>
            <Select
              placeholder={t('Select account type')}
              bordered
              options={[
                { label: t('Personal'), value: UserAccountType.PERSONAL },
                { label: t('Business'), value: UserAccountType.BUSINESS },
              ]}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.accountType !== nextValues.accountType}>
            {({ getFieldValue }) => {
              const isBusinessAccountType = getFieldValue('accountType') === UserAccountType.BUSINESS;

              return isBusinessAccountType ? (
                <Form.Item label={t('Company')} name="company">
                  <Input placeholder={t('Enter company')} bordered />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
        </div>
      </div>

      {isAddressDataNeeded && (
        <div>
          <Divider className="border-secondaryAccent" />
          <div className="font-semibold text-2xl mb-4">{t('Address')}</div>
          <div className="flex gap-4">
            <Form.Item
              label={t('Country')}
              name="country"
              rules={[{ required: true, message: t('Please, enter country') }]}
              className="flex-1"
            >
              <Input placeholder={t('Enter country')} bordered />
            </Form.Item>
            <Form.Item label={t('City')} name="city" rules={[{ required: true, message: t('Please, enter city') }]} className="flex-1">
              <Input placeholder={t('Enter city')} bordered />
            </Form.Item>
            <Form.Item
              label={t('Zip code')}
              name="zip"
              rules={[{ required: true, message: t('Please, enter zip code') }]}
              className="flex-2"
            >
              <Input placeholder={t('Enter zip code')} bordered />
            </Form.Item>
          </div>
          <div className="flex gap-4">
            <Form.Item
              label={t('Street')}
              name="street"
              rules={[{ required: true, message: t('Please, enter street') }]}
              className="flex-1"
            >
              <Input placeholder={t('Enter street')} bordered />
            </Form.Item>
            <Form.Item label={t('House')} name="house" rules={[{ required: true, message: t('Please, enter house') }]} className="flex-2">
              <Input placeholder={t('Enter house')} bordered />
            </Form.Item>
            <Form.Item label={t('Apartment')} name="apartment" className="flex-2">
              <Input placeholder={t('Enter apartment')} bordered />
            </Form.Item>
          </div>
        </div>
      )}

      <div className="flex justify-end">
        <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.role !== nextValues.role}>
          <Button type="submit">{t(isAdditionalStepsNeeded ? 'Next' : 'Create')}</Button>
        </Form.Item>
      </div>
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Create user')}
        description={t('Are you sure you want to create user?')}
        isLoading={isLoading}
        onOk={handleCreateUser}
        onCancel={closeConfirmModal}
      />
    </Form>
  );
});
