export enum InvoiceType {
  LATE_CHARGE = 'LateCharge',
  RENTAL = 'Rental',
  DEPOSIT = 'Deposit',
  PERSONAL = 'Personal',
  RENTAL_WITH_DEPOSIT = 'Rental with deposit',
  OTHER = 'Other',
}

export enum ContractApplicabilityFilter {
  APPLICABLE = 'applicable',
  INAPPLICABLE = 'inapplicable',
}

export enum InvoicesListFilterKeys {
  selectedWarehouses = 'selectedWarehouses',
  isActive = 'isActive',
  invoiceTypes = 'invoiceTypes',
  invoiceAmount = 'invoiceAmount',
  invoicingDateFrom = 'invoicingDateFrom',
  invoicingDateTo = 'invoicingDateTo',
  creationDateFrom = 'creationDateFrom',
  creationDateTo = 'creationDateTo',
  dueDateFrom = 'dueDateFrom',
  dueDateTo = 'dueDateTo',
  status = 'status',
  invoiceNumberFrom = 'invoiceNumberFrom',
  invoiceNumberTo = 'invoiceNumberTo',
  isApplicable = 'isApplicable',
  statusRefDate = 'statusRefDate',
}
