import { useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { ContractInfo, getEndRentDateString, useCreateContractMutation } from 'entities/Contract';
import { getServerDateFormat } from 'shared/utils/helpers/getDateFormat';

interface HookArgs {
  userId: string | undefined;
  warehouseId: string | undefined;
  boxId: string | undefined;
  contractOptions: ContractInfo;
  onSuccess?: () => void;
}

interface HookApi {
  isOpenedConfirm: boolean;
  isLoading: boolean;
  handleCreateContract: () => void;
  openConfirmModal: () => void;
  closeConfirmModal: () => void;
}

export const useCreateContract = ({ contractOptions, boxId, userId, warehouseId, onSuccess }: HookArgs): HookApi => {
  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const { t } = useAppTranslation('common');

  const [createContract, { isLoading }] = useCreateContractMutation();

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  const handleCreateContract = useCallback(async (): Promise<void> => {
    try {
      if (userId && warehouseId && boxId && contractOptions.startDate && contractOptions.rentOption) {
        const {
          startDate,
          endDate,
          invoiceFrequencyType,
          contractDuration,
          payForEntirePeriod,
          insuranceId,
          rentOption,
          balanceToChargeAmount,
          promoCodeValue,
        } = contractOptions;

        const endRentDate = getEndRentDateString({ startDate, endDate, contractDuration, invoiceFrequencyType });

        await createContract({
          boxId,
          params: {
            userId,
            warehouseId,
            invoiceFrequencyType,
            invoiceFrequency: 1,
            paymentFrequencyPeriod: payForEntirePeriod ? 'entire period' : 'regular',
            startDate: getServerDateFormat(startDate),
            endDate: getServerDateFormat(endRentDate),
            insuranceId: insuranceId || undefined,
            rentOptionId: rentOption.rentOptionId,
            useUserBalance: balanceToChargeAmount > 0,
            promoCode: promoCodeValue,
          },
        }).unwrap();

        showNotification('info', t('Success'), t('Contract has been successfully created'));

        onSuccess?.();
      }
    } catch (error: CustomAny) {
      console.log(error);
      showNotification('error', t('Error'), t('Error when creating contract'));
    } finally {
      closeConfirmModal();
    }
  }, [userId, warehouseId, boxId, contractOptions, createContract, t, onSuccess, closeConfirmModal]);

  return {
    openConfirmModal,
    closeConfirmModal,
    handleCreateContract,
    isOpenedConfirm,
    isLoading,
  };
};
