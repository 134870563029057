import { AppLanguage } from 'app/config/i18Config/types';

export const CLIENT_DATE_FORMAT = 'DD.MM.YYYY';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export const CLIENT_DATE_WITH_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export const APP_NAME = process.env.REACT_APP_NAME;

export const APP_LANGUAGE_KEY = `${process.env.REACT_APP_NAME}-language`;

export const languageToLabelMap: Record<AppLanguage, string> = {
  [AppLanguage.en]: 'English',
  [AppLanguage.de]: 'Deutsch',
  [AppLanguage.pl]: 'Polski',
};

export const languageToShortLabelMap: Record<AppLanguage, string> = {
  [AppLanguage.en]: 'EN',
  [AppLanguage.de]: 'DE',
  [AppLanguage.pl]: 'PL',
};
