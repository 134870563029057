import { InvoiceStatus } from 'entities/Invoice';
import { TableFilterType } from 'features/TableFilter';
import { getFilterServerFormatDate } from 'shared/utils/helpers/getDateFormat';

interface InvoiceListFilters {
  warehouseId?: string;
  isActive?: boolean;
  invoiceTypes?: boolean;
  invoiceAmount?: number;
  invoicingDateFrom?: string;
  invoicingDateTo?: string;
  dueDateFrom?: string;
  dueDateTo?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  status?: InvoiceStatus;
  invoiceNumberFrom?: string;
  invoiceNumberTo?: string;
  isApplicable?: boolean;
  statusRefDate?: string;
}

export const transformInvoicesListFilters = ({
  selectedWarehouses,
  invoiceTypes,
  isActive,
  invoiceAmount,
  invoicingDateFrom,
  invoicingDateTo,
  creationDateFrom,
  creationDateTo,
  dueDateFrom,
  dueDateTo,
  status,
  invoiceNumberFrom,
  invoiceNumberTo,
  isApplicable,
  statusRefDate,
}: TableFilterType): InvoiceListFilters => {
  return {
    warehouseId: selectedWarehouses?.value || undefined,
    isActive: isActive?.value === undefined ? undefined : isActive?.value === 'active',
    invoiceTypes: invoiceTypes?.value || undefined,
    isApplicable: isApplicable?.value === undefined ? undefined : isApplicable?.value === 'applicable',
    invoiceAmount: invoiceAmount?.value || undefined,
    invoicingDateFrom: getFilterServerFormatDate(invoicingDateFrom?.value),
    invoicingDateTo: getFilterServerFormatDate(invoicingDateTo?.value),
    creationDateFrom: getFilterServerFormatDate(creationDateFrom?.value),
    creationDateTo: getFilterServerFormatDate(creationDateTo?.value),
    dueDateFrom: getFilterServerFormatDate(dueDateFrom?.value),
    dueDateTo: getFilterServerFormatDate(dueDateTo?.value),
    status: status?.value || undefined,
    invoiceNumberFrom: invoiceNumberFrom?.value || undefined,
    invoiceNumberTo: invoiceNumberTo?.value || undefined,
    statusRefDate: getFilterServerFormatDate(statusRefDate?.value),
  };
};
