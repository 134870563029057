import React, { FC, useCallback, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Warehouse, useOpenWarehouseDoorMutation } from 'entities/Warehouse';
import { ReactComponent as LocationIcon } from 'shared/assets/icons/LocationIcon.svg';

import { ReactComponent as WifiIcon } from 'shared/assets/icons/WifiIcon.svg';
import { ReactComponent as LockIcon } from 'shared/assets/icons/LockIcon.svg';
import { ReactComponent as KeyIcon } from 'shared/assets/icons/KeyIcon.svg';

import { InfoTag } from 'shared/ui/InfoTag';
import { Button } from 'shared/ui/Button';
import { MessagePanel } from 'shared/ui/MessagePanel';
import { Modal } from 'shared/ui/Modal';
import { ShowOnMapButton } from 'features/ShowOnMapButton';
import { CopyTooltip } from 'shared/ui/CopyTooltip';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useGeolocation } from 'shared/utils/hooks/useGeolocation';
import { ConfirmModal } from 'shared/ui/ConfirmModal';

interface WarehouseCardProps {
  warehouse: Nullable<Warehouse>;
  type: 'reservation' | 'contract';
}

export const WarehouseCard: FC<WarehouseCardProps> = (props) => {
  const { warehouse, type } = props;
  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const [isOpenedConfirm, setOpenedConfirm] = useState<boolean>(false);

  const { t } = useAppTranslation(['contracts', 'common']);

  const coords = useGeolocation();

  const [openWarehouseDoor, { isLoading: isOpenLoading }] = useOpenWarehouseDoorMutation();

  const isReservation = type === 'reservation';

  const fullAddress = `${warehouse?.address} ${warehouse?.zip || ''}`;

  const hasMoreThanOneEquipments = warehouse?.internalEquipments && warehouse.internalEquipments.length > 1;

  const handleCloseModal = useCallback((): void => {
    setModalOpened(false);
  }, []);

  const handleOpenModal = useCallback((): void => {
    setModalOpened(true);
  }, []);

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  const handleOpenDoor = useCallback(
    async (lockAddress: Nullable<string>): Promise<void> => {
      if (warehouse && lockAddress && coords) {
        try {
          await openWarehouseDoor({
            warehouseId: warehouse.warehouseId,
            args: { lockAddress, longitude: coords?.lng, latitude: coords?.lat },
          }).unwrap();
          showNotification('info', t('Success', { ns: 'common' }), t('The door has been successfully opened', { ns: 'common' }));
        } catch (error: CustomAny) {
          console.log(error);
          const errorMessage = t(error?.data?.message?.[0] || 'Error while opening door', { ns: 'common' });

          showNotification('error', t('Error', { ns: 'common' }), errorMessage);
        }
      }
    },
    [coords?.lat, coords?.lng, openWarehouseDoor, t, warehouse],
  );

  const singleInternalEquipmentLockAddress = useMemo(() => {
    if (warehouse?.internalEquipments && warehouse?.internalEquipments[0]?.lockAddress) {
      return warehouse?.internalEquipments[0]?.lockAddress;
    }
  }, [warehouse?.internalEquipments]);

  return (
    <div className="flex flex-col justify-between gap-6 desktop:flex-row">
      <div className="flex flex-col gap-2 desktop:w-1/2">
        <div className="font-normal mb-2 desktop:font-semibold">{getLocalizedString(warehouse?.description)}</div>
        <div className="flex items-center">
          <LocationIcon className="mr-2 stroke-accent" />
          <CopyTooltip className="text-accent underline cursor-pointer text-sm font-normal">{fullAddress}</CopyTooltip>
        </div>
        {!isReservation && (
          <>
            <div className="flex items-center gap-4">
              <div className="flex items-center text-primaryLight text-sm font-normal break-all">
                <WifiIcon className="mr-2 stroke-accent shrink-0" />
                {warehouse?.wifiNetworkName}
              </div>
              <div className="flex items-center text-primaryLight text-sm font-normal break-all">
                <LockIcon className="mr-2 stroke-accent shrink-0" />
                <CopyTooltip className="text-accent underline cursor-pointer text-sm font-normal">{warehouse?.wifiPassword}</CopyTooltip>
              </div>
            </div>

            <div className="mt-2 desktop:w-2/3">
              <InfoTag>{t('Connect to the warehouse`s WIFI or being no more than 500m')}</InfoTag>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col gap-2 desktop:w-1/4">
        {warehouse?.latitude && warehouse?.longitude && (
          <ShowOnMapButton latitude={warehouse.latitude} longitude={warehouse.longitude} theme="outlined" />
        )}
        {!isReservation && (
          <>
            {hasMoreThanOneEquipments ? (
              <Button icon={<KeyIcon className="stroke-white" />} iconPosition="prev" onClick={handleOpenModal}>
                {t('Doors')}
              </Button>
            ) : (
              <Button icon={<KeyIcon className="stroke-white" />} iconPosition="prev" onClick={openConfirmModal}>
                {t('Open')}
              </Button>
            )}
          </>
        )}
      </div>

      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Open door', { ns: 'common' })}
        description={t('Are you sure you want to open door', { ns: 'common' })}
        isLoading={isOpenLoading}
        onOk={() => {
          if (singleInternalEquipmentLockAddress) {
            void handleOpenDoor(singleInternalEquipmentLockAddress);
          }
        }}
        onCancel={closeConfirmModal}
      />

      <Modal isOpen={isModalOpened} onClose={handleCloseModal} closeIcon={false} paddingLess width={360} footer={false}>
        <MessagePanel
          className="w-[360px]"
          type="info"
          title={t('Choose the door you need')}
          description={t('Click the button to access')}
          actions={
            <div className="flex flex-col justify-center gap-3">
              {hasMoreThanOneEquipments &&
                warehouse.internalEquipments?.map((equipment) => (
                  <Button
                    isLoading={isOpenLoading}
                    key={equipment.internalEquipmentId}
                    onClick={() => {
                      void handleOpenDoor(equipment.lockAddress);
                    }}
                  >
                    {getLocalizedString(equipment.name)}
                  </Button>
                ))}

              <Button theme="outlined" onClick={handleCloseModal} isLoading={isOpenLoading} className="mt-4">
                {t('Cancel')}
              </Button>
            </div>
          }
        />
      </Modal>
    </div>
  );
};
