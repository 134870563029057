import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import i18n from 'app/config/i18Config/i18n';
import { Divider, Form } from 'antd';
import { RadioButtonGroup } from 'shared/ui/RadioButtonGroup';
import { ContentLine } from 'shared/ui/ContentLine';
import { Box } from 'entities/Box';
import { useContractOptions } from 'entities/Contract';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { DatePicker } from 'shared/ui/DatePicker';
import { Select } from 'shared/ui/Select';
import { InfoTag } from 'shared/ui/InfoTag';
import { useGetCurrencySymbol } from 'app/appState';
import { InvoiceFrequencyType } from 'entities/Invoice';
import { User, UserAccountType } from 'entities/User';
import { PaymentFrequencySetting } from 'entities/RentOption';
import { PriceDetailsTable } from 'features/PriceDetailsTable';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { AppLanguage } from 'app/config/i18Config/types';
import { WarehouseForBooking } from 'entities/Warehouse';
import { useQueryString } from 'shared/utils/helpers/convertQueryString';
import { Toggle } from 'shared/ui/Toggle';
import { Input } from 'shared/ui/Input';
import { Button } from 'shared/ui/Button';

interface ContractDetailsProps {
  selectedWarehouse: Nullable<WarehouseForBooking>;
  boxInfo: Nullable<Box>;
  userInfo: Nullable<User>;
  userAccountType: UserAccountType | undefined;
}

export const ContractDetails: FC<ContractDetailsProps> = memo((props) => {
  const { selectedWarehouse, boxInfo, userAccountType, userInfo } = props;

  const { t } = useAppTranslation('booking');

  const currentLanguage = i18n.language as AppLanguage;

  const currencySymbol = useGetCurrencySymbol();

  const { parsedQuery } = useQueryString();

  const [promoCode, setPromoCode] = useState<string>('');

  const {
    contractOptions,
    insuranceOptions,
    availableRentOptions,
    changeRentOption,
    handleEndDateChange,
    disabledEndDate,
    handleInsuranceChange,
    disabledStartDate,
    handleEntirePaymentChange,
    handleStartDateChange,
    firstAvailableEndDate,
    firstAvailableStartDate,
    useUserBalance,
    setUseUserBalance,
    setPromoCodeValue,
    promoDiscounts,
  } = useContractOptions({
    userAccountType,
    user: userInfo,
    selectedWarehouse,
    box: boxInfo,
  });

  const isPromoApplied = useMemo(() => Boolean(promoDiscounts?.length), [promoDiscounts]);

  const isWrongPromo = useMemo(() => promoDiscounts?.length === 0, [promoDiscounts]);

  const contractDurationOptions = useMemo<SelectOption[]>(() => {
    return availableRentOptions.map((rentOption) => ({
      label: getLocalizedString(rentOption.label),
      value: rentOption.rentOptionId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableRentOptions, currentLanguage]);

  const changeContractDuration = useCallback(
    (rentOptionId: string) => {
      const selectedRentOption = availableRentOptions.find((rentOption) => rentOption.rentOptionId === rentOptionId);

      if (selectedRentOption) {
        changeRentOption(selectedRentOption);
        setPromoCode(contractOptions.promoCodeValue || '');
      }
    },
    [availableRentOptions, changeRentOption, contractOptions.promoCodeValue],
  );

  const applyPromoCode = (): void => {
    setPromoCodeValue(promoCode);
  };

  useEffect(() => {
    parsedQuery.rentOptionId && availableRentOptions && changeContractDuration(parsedQuery.rentOptionId);
  }, [availableRentOptions, parsedQuery.rentOptionId]);

  return (
    <div>
      <div className="flex flex-col-reverse desktop:flex-row desktop:space-x-6">
        <div className="flex-1">
          <div className="font-semibold text-base mb-1">{t('Contract duration')}</div>
          <div className="text-sm text-primaryLight mb-3">
            <sup>*</sup>
            {t(
              'It is a permanent contract with a minimum contract period. After the minimum contract period has expired, the contract is automatically renewed monthly until it is terminated with a notice period of 14 days.',
            )}
          </div>
          <div className="mt-4">
            <RadioButtonGroup
              options={contractDurationOptions}
              direction="vertical"
              withBorder
              onChange={changeContractDuration}
              value={contractOptions?.rentOption?.rentOptionId}
              labelPosition="post"
              optionType="default"
            />
          </div>
          {contractOptions?.rentOption?.description ? (
            <div className="text-sm text-primaryLight mt-4">{getLocalizedString(contractOptions.rentOption.description)}</div>
          ) : null}
        </div>
        <div className="mb-6 desktop:flex-1 desktop:p-6 desktop:mb-0">
          <PriceDetailsTable boxInfo={boxInfo} contractInfo={contractOptions} />
        </div>
      </div>

      <Divider />

      <div className="font-semibold text-base mb-1">{t('Have a promo code ?')}</div>
      {isPromoApplied && <div className="font-semibold text-success mb-1">{t('Promo has been successfully applied')}</div>}
      {isWrongPromo && <div className="font-semibold text-error mb-1">{t('Wrong promo code, please try another one')}</div>}
      <div className="flex items-center">
        <Input
          onChange={setPromoCode}
          value={promoCode}
          containerClassName="w-full max-w-[230px]"
          className="rounded-tr-none rounded-br-none"
          placeholder={t('XXX-XXX')}
          disabled={isPromoApplied}
          bordered
        />
        <Button
          isDisabled={isPromoApplied}
          className="py-[14px] rounded-tr-lx rounded-br-lx rounded-bl-none rounded-tl-none"
          theme="primary"
          onClick={applyPromoCode}
        >
          {t('Apply')}
        </Button>
      </div>

      <div className="flex gap-6 flex-col-reverse desktop:flex-row desktop:space-x-6">
        {Boolean(userInfo?.balance) && (
          <div className="flex-1">
            <InfoTag textSize="medium">
              {t('You have {{balance}} {{currencySymbol}} on your balance, you can use it to pay your booking', {
                balance: userInfo?.balance,
                currencySymbol,
              })}
            </InfoTag>
            <div className="font-semibold text-base mt-4 mb-1">{t('Personal balance')}</div>
            <div className="flex items-center w-full max-w-[580px] rounded-lg p-2 border border-secondaryAccent">
              <Toggle checked={useUserBalance} onChange={setUseUserBalance} />
              <div className="text-m">{t('Use your balance')}</div>
            </div>
          </div>
        )}
        {contractOptions?.rentOption?.paymentFrequencySetting === PaymentFrequencySetting.ALL && (
          <div className="flex-1">
            <InfoTag textSize="medium">{t('Discount information for entire period!')}</InfoTag>
            <div className="font-semibold text-base mt-4 mb-1">{t('Prepayment')}</div>
            <div className="flex items-center w-full max-w-[580px] rounded-lg p-2 border border-secondaryAccent">
              <Toggle checked={contractOptions.payForEntirePeriod} onChange={handleEntirePaymentChange} />
              <div className="text-m">{t('Pay for the entire period')}</div>
            </div>
          </div>
        )}
      </div>

      <Divider />

      <ContentLine title={t('Move-in date')}>
        <DatePicker
          className="py-[8px] px-[16px] h-[56px] w-full max-w-[280px] desktop:h-[56px]"
          format={CLIENT_DATE_FORMAT}
          onChange={handleStartDateChange}
          placeholder={t(CLIENT_DATE_FORMAT)}
          inputReadOnly
          requiredMark
          disabledDate={disabledStartDate}
          value={contractOptions.startDate}
          defaultPickerValue={firstAvailableStartDate}
        />
      </ContentLine>

      <Divider />

      {contractOptions.invoiceFrequencyType === InvoiceFrequencyType.DAY && (
        <div>
          <ContentLine title={t('Move-out date')}>
            <DatePicker
              format={CLIENT_DATE_FORMAT}
              className="py-[8px] px-[16px] h-[56px] w-full max-w-[280px] desktop:h-[56px]"
              onChange={handleEndDateChange}
              placeholder={t(CLIENT_DATE_FORMAT)}
              disabled={!contractOptions.startDate}
              inputReadOnly
              requiredMark
              disabledDate={disabledEndDate}
              showToday={false}
              value={contractOptions.endDate}
              defaultPickerValue={firstAvailableEndDate}
            />
          </ContentLine>

          <Divider />
        </div>
      )}

      <ContentLine
        title={
          <div className="flex flex-col">
            <span className="font-semibold">{t('Coverage amount')}</span>
          </div>
        }
        description={t('Storage insurance is a requirement of self storage')}
      >
        <Form.Item name="insuranceId">
          {!insuranceOptions?.length ? (
            <InfoTag>{t('No insurance provided!')}</InfoTag>
          ) : (
            <div>
              <Select
                className="!h-[56px] w-full max-w-[280px]"
                placeholder={t('Select')}
                onChange={handleInsuranceChange}
                options={insuranceOptions}
                value={contractOptions.insuranceId}
                bordered
                requiredMark
              />
              {contractOptions.insuranceId && (
                <div className="text-sm mt-2 pl-1 font-semibold">
                  {t(`{{rate}} {{currencySymbol}}/${contractOptions.invoiceFrequencyType}`, {
                    rate: contractOptions.insuranceRate,
                    currencySymbol,
                  })}
                </div>
              )}
            </div>
          )}
        </Form.Item>
      </ContentLine>
    </div>
  );
});
