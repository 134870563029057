import { useCallback, useState } from 'react';
import { GeolocationCoords } from 'app/types/common';
import { useGeolocation } from 'shared/utils/hooks/useGeolocation';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { globalConfirmModalActions } from 'app/globalConfirmModal';

interface UseMapRouteReturnType {
  directionResult: Nullable<google.maps.DirectionsResult>;
  calculateRoute: (destination: GeolocationCoords) => Promise<google.maps.DirectionsResult | undefined>;
  resetRoute: () => void;
}

export const useMapRoute = (): UseMapRouteReturnType => {
  const coords = useGeolocation();

  const { t } = useAppTranslation('common');
  const dispatch = useAppDispatch();

  const [directionResult, setDirectionResult] = useState<Nullable<google.maps.DirectionsResult>>(null);

  const resetRoute = useCallback((): void => {
    setDirectionResult(null);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    dispatch(globalConfirmModalActions.setGlobalConfirmModalData(null));
  }, [dispatch]);

  const calculateRoute = useCallback(
    async (destination: GeolocationCoords): Promise<google.maps.DirectionsResult | undefined> => {
      resetRoute();

      try {
        const origin = coords;

        if (origin) {
          const directionService = new google.maps.DirectionsService();

          const result = await directionService.route({
            origin,
            destination,
            travelMode: google.maps.TravelMode.DRIVING,
          });

          setDirectionResult(result);

          return result;
        } else {
          dispatch(
            globalConfirmModalActions.setGlobalConfirmModalData({
              title: t('Geolocation Permission Denied'),
              description: t(
                'The browser is unable to access your geolocation because permission is not enabled. Please enable location services to allow the website to determine your location.',
              ),
              onOk: closeConfirmModal,
            }),
          );
        }
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when calculating route'));
      }
    },
    [closeConfirmModal, coords, dispatch, resetRoute, t],
  );

  return { directionResult, calculateRoute, resetRoute };
};
