import { Divider, Form } from 'antd';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useGetCurrencySymbol } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { useContractOptions } from 'entities/Contract';
import { InvoiceFrequencyType } from 'entities/Invoice';
import { Button } from 'shared/ui/Button';
import { ContentLine } from 'shared/ui/ContentLine';
import { DatePicker } from 'shared/ui/DatePicker';
import { InfoTag } from 'shared/ui/InfoTag';
import { RadioButtonGroup } from 'shared/ui/RadioButtonGroup';
import { Select } from 'shared/ui/Select';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { ReserveBox } from 'features/ReserveBox';
import { CreateContract } from 'features/CreateContract';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { getSelectedBox } from '../../model/selectors/getSelectedBox';
import { getSelectedUser } from '../../model/selectors/getSelectedUser';
import { getSelectedWarehouse } from '../../model/selectors/getSelectedWarehouse';
import { PaymentFrequencySetting } from 'entities/RentOption';
import { PriceDetailsTable } from 'features/PriceDetailsTable';
import { Toggle } from 'shared/ui/Toggle';
import { Input } from 'shared/ui/Input';

interface BookingOptionsStepProps {
  onClose: () => void;
}

export const BookingOptionsStep: FC<BookingOptionsStepProps> = memo((props) => {
  const { onClose } = props;

  const { t } = useAppTranslation('contracts');

  const currencySymbol = useGetCurrencySymbol();

  const selectedWarehouse = useAppSelector(getSelectedWarehouse);
  const selectedUser = useAppSelector(getSelectedUser);
  const selectedBox = useAppSelector(getSelectedBox);

  const [promoCode, setPromoCode] = useState<string>('');

  const {
    contractOptions,
    availableRentOptions,
    changeRentOption,
    insuranceOptions,
    handleEndDateChange,
    disabledEndDate,
    handleInsuranceChange,
    disabledStartDate,
    handleEntirePaymentChange,
    handleStartDateChange,
    resetContractOptions,
    firstAvailableEndDate,
    firstAvailableStartDate,
    useUserBalance,
    setUseUserBalance,
    setPromoCodeValue,
    promoDiscounts,
  } = useContractOptions({
    userAccountType: selectedUser?.accountType,
    user: selectedUser,
    selectedWarehouse,
    box: selectedBox,
  });

  const isCreationDisabled = useMemo<boolean>(() => {
    let isDisabled = !contractOptions.startDate || !contractOptions.insuranceId;

    if (contractOptions.invoiceFrequencyType === 'day') {
      isDisabled = isDisabled || !contractOptions.endDate;
    }

    return isDisabled;
  }, [contractOptions]);

  const isPromoApplied = useMemo(() => Boolean(promoDiscounts?.length), [promoDiscounts]);

  const isWrongPromo = useMemo(() => promoDiscounts?.length === 0, [promoDiscounts]);

  const contractDurationOptions = useMemo<SelectOption[]>(() => {
    return availableRentOptions.map((rentOption) => ({
      label: getLocalizedString(rentOption.label),
      value: rentOption.rentOptionId,
    }));
  }, [availableRentOptions]);

  const changeContractDuration = useCallback(
    (rentOptionId: string) => {
      const selectedRentOption = availableRentOptions.find((rentOption) => rentOption.rentOptionId === rentOptionId);

      if (selectedRentOption) {
        changeRentOption(selectedRentOption);
        setPromoCode(contractOptions.promoCodeValue || '');
      }
    },
    [availableRentOptions, changeRentOption, contractOptions.promoCodeValue],
  );

  const applyPromoCode = (): void => {
    setPromoCodeValue(promoCode);
  };

  const [form] = Form.useForm();

  const closeModal = useCallback(() => {
    onClose();
    resetContractOptions();
  }, [onClose, resetContractOptions]);

  return (
    <Form className="flex flex-col" name="bookingOptions" form={form}>
      <div className="font-semibold text-2xl mb-4">{t('Booking options')}</div>
      <div className="flex space-x-20 max-h-[530px] overflow-y-auto mb-4">
        <div>
          <div className="flex flex-col-reverse desktop:flex-row desktop:space-x-6">
            <div className="flex-1">
              <div className="font-semibold text-base mb-1">{t('Contract duration')}</div>
              <div className="text-sm text-primaryLight mb-3">
                <sup>*</sup>
                {t(
                  'It is a permanent contract with a minimum contract period. After the minimum contract period has expired, the contract is automatically renewed monthly until it is terminated with a notice period of 14 days.',
                )}
              </div>
              <div className="mt-4">
                <RadioButtonGroup
                  options={contractDurationOptions}
                  direction="vertical"
                  withBorder
                  onChange={changeContractDuration}
                  value={contractOptions?.rentOption?.rentOptionId}
                  labelPosition="post"
                  optionType="default"
                />
              </div>
              {contractOptions?.rentOption?.description ? (
                <div className="text-sm text-primaryLight mt-4">{getLocalizedString(contractOptions.rentOption.description)}</div>
              ) : null}
            </div>
            <div className="mb-6 desktop:flex-1 desktop:p-6 desktop:mb-0">
              <PriceDetailsTable boxInfo={selectedBox} contractInfo={contractOptions} />
            </div>
          </div>

          <Divider />

          {isPromoApplied && <div className="font-semibold text-success mb-1">{t('Promo has been successfully applied')}</div>}
          {isWrongPromo && <div className="font-semibold text-error mb-1">{t('Wrong promo code, please try another one')}</div>}
          <div className="flex items-center">
            <Input
              onChange={setPromoCode}
              value={promoCode}
              containerClassName="w-full max-w-[230px]"
              className="rounded-tr-none rounded-br-none"
              placeholder={t('XXX-XXX')}
              disabled={isPromoApplied}
              bordered
            />
            <Button
              isDisabled={isPromoApplied}
              className="py-[14px] rounded-tr-lx rounded-br-lx rounded-bl-none rounded-tl-none"
              theme="primary"
              onClick={applyPromoCode}
            >
              {t('Apply')}
            </Button>
          </div>

          <div className="flex gap-6 flex-col-reverse desktop:flex-row desktop:space-x-6">
            {Boolean(selectedUser?.balance) && (
              <div className="flex-1">
                <InfoTag textSize="medium">
                  {t('You have {{balance}} {{currencySymbol}} on your balance, you can use it to pay your booking', {
                    balance: selectedUser?.balance,
                    currencySymbol,
                  })}
                </InfoTag>
                <div className="font-semibold text-base mt-4 mb-1">{t('Personal balance')}</div>
                <div className="flex items-center w-full max-w-[580px] rounded-lg p-2 border border-secondaryAccent">
                  <Toggle checked={useUserBalance} onChange={setUseUserBalance} />
                  <div className="text-m">{t('Use your balance')}</div>
                </div>
              </div>
            )}
            {contractOptions?.rentOption?.paymentFrequencySetting === PaymentFrequencySetting.ALL && (
              <div className="flex-1">
                <InfoTag textSize="medium">{t('Discount information for entire period!')}</InfoTag>
                <div className="font-semibold text-base mt-4 mb-1">{t('Prepayment')}</div>
                <div className="flex items-center w-full max-w-[580px] rounded-lg p-2 border border-secondaryAccent">
                  <Toggle checked={contractOptions.payForEntirePeriod} onChange={handleEntirePaymentChange} />
                  <div className="text-m">{t('Pay for the entire period')}</div>
                </div>
              </div>
            )}
          </div>

          <Divider />

          <ContentLine title={t('Move-in date')}>
            <DatePicker
              className="py-[8px] px-[16px] h-[56px] w-full max-w-[280px] desktop:h-[56px]"
              format={CLIENT_DATE_FORMAT}
              onChange={handleStartDateChange}
              placeholder={t(CLIENT_DATE_FORMAT)}
              inputReadOnly
              requiredMark
              disabledDate={disabledStartDate}
              value={contractOptions.startDate}
              defaultPickerValue={firstAvailableStartDate}
            />
          </ContentLine>

          <Divider />

          {contractOptions.invoiceFrequencyType === InvoiceFrequencyType.DAY && (
            <>
              <ContentLine title={t('Move-out date')}>
                <DatePicker
                  format={CLIENT_DATE_FORMAT}
                  className="py-[8px] px-[16px] h-[56px] w-full max-w-[280px] desktop:h-[56px]"
                  onChange={handleEndDateChange}
                  placeholder={t(CLIENT_DATE_FORMAT)}
                  disabled={!contractOptions.startDate}
                  inputReadOnly
                  disabledDate={disabledEndDate}
                  requiredMark
                  value={contractOptions.endDate}
                  defaultPickerValue={firstAvailableEndDate}
                />
              </ContentLine>
              <Divider />
            </>
          )}

          <ContentLine
            title={
              <div className="flex flex-col">
                <span className="font-semibold">{t('Insurance')}</span>
                <span className="font-semibold">{t('Coverage amount')}</span>
              </div>
            }
            description={t('Storage insurance is a requirement of self storage')}
          >
            <Form.Item name="insuranceId">
              {!insuranceOptions?.length ? (
                <InfoTag>{t('No insurance provided!')}</InfoTag>
              ) : (
                <div>
                  <Select
                    className="!h-[56px] w-full max-w-[280px]"
                    placeholder={t('Select')}
                    onChange={handleInsuranceChange}
                    options={insuranceOptions}
                    value={contractOptions.insuranceId}
                    bordered
                    requiredMark
                  />
                  {contractOptions.insuranceId && (
                    <div className="text-sm mt-2 pl-1 font-semibold">
                      {t(`{{rate}} {{currencySymbol}}/${contractOptions.invoiceFrequencyType}`, {
                        rate: contractOptions.insuranceRate,
                        currencySymbol,
                      })}
                    </div>
                  )}
                </div>
              )}
            </Form.Item>
          </ContentLine>
        </div>
      </div>
      <div className="border-t flex justify-end w-full">
        <div className="flex space-x-2.5 self-end mt-4">
          <ReserveBox
            contractOptions={contractOptions}
            boxId={selectedBox?.boxId}
            userId={selectedUser?.userId}
            action={
              <Button theme="secondary" isDisabled={isCreationDisabled}>
                {t('Create Reservation')}
              </Button>
            }
            warehouseId={selectedWarehouse?.warehouseId}
            onSuccess={closeModal}
          />
          <CreateContract
            contractOptions={contractOptions}
            warehouseId={selectedWarehouse?.warehouseId}
            boxId={selectedBox?.boxId}
            userId={selectedUser?.userId}
            action={<Button isDisabled={isCreationDisabled}>{t('Create Contract')}</Button>}
            onSuccess={closeModal}
          />
        </div>
      </div>
    </Form>
  );
});
